import '../styles/globals.css'
import Script from 'next/script'

function MyApp({ Component, pageProps }) {
  return (
      <Component { ...pageProps } />
  )
}

export default MyApp
